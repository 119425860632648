import { Toaster } from 'react-hot-toast';

// custom toast options additionally set in `toast-utils`
export const ToastNotification = () => {
  return (
    <Toaster
      position="bottom-right"
      gutter={8}
      containerClassName="md:right-0! bottom-0! mb-8! mx-10! mx-4! right-0! left-0!"
      toastOptions={{
        className: 'w-full md:w-80!',
        style: {
          padding: '16px',
          borderRadius: '8px',
          color: '#fff',
          backgroundColor: 'var(--color-gray-900)',
          boxShadow: '0px 16px 48px 0px rgba(0, 0, 0, 0.16)',
          fontSize: '16px',
        },
        duration: Infinity,

        success: {
          iconTheme: {
            primary: 'var(--color-success-400)',
            secondary: 'var(--color-gray-900)',
          },
        },
      }}
    />
  );
};
