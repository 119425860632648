import { AppLayout } from '@components/layouts/AppLayout';
import { KeyboardShortcutProvider } from '@contexts/KeyboardShortcut/KeyboardShortcutProvider';
import { PreviousLocationProvider } from '@contexts/PreviousLocation/PreviousLocationProvider';
import { StatsigProvider } from '@contexts/StatsigProvider';
import { ReactNode } from 'react';
import { ThemeProvider } from './contexts/ThemeProvider';
import { UrqlClientProvider } from './contexts/UrqlClientProvider';
import { PortalUserProvider } from './contexts/User/PortalUserProvider';

const AppProviders = ({ children }: { children: ReactNode }) => (
  <PortalUserProvider>
    <StatsigProvider>
      <PreviousLocationProvider>
        <UrqlClientProvider>
          <ThemeProvider>
            <KeyboardShortcutProvider>{children}</KeyboardShortcutProvider>
          </ThemeProvider>
        </UrqlClientProvider>
      </PreviousLocationProvider>
    </StatsigProvider>
  </PortalUserProvider>
);

const App = () => {
  return (
    <AppProviders>
      <AppLayout />
    </AppProviders>
  );
};

export default App;
