import { Link } from '@components/common/Link/Link';
import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  errorCode?: number;
  typeOfError?: string;
  children?: ReactNode;
  /**
   * Based on Tailwind className
   * @see https://tailwindcss.com/docs/height
   */
  height?: string;
};

export const GenericErrorPage = ({
  errorCode = 500,
  typeOfError = 'Internal Server Error',
  children,
  height,
}: Props) => {
  return (
    <section
      className={clsx(
        'flex h-[calc(100vh-64px)] items-center justify-center bg-white',
        height,
      )}
    >
      <div className="mx-auto max-w-(--breakpoint-xl) px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-(--breakpoint-sm) text-center">
          <h1 className="text-product-dark mb-4 text-7xl font-extrabold tracking-tight lg:text-9xl">
            {errorCode}
          </h1>
          <p className="mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
            {typeOfError}
          </p>
          {children ?? (
            <div className="flex flex-col items-center justify-center gap-4">
              <p className="text-lg font-light text-gray-500 dark:text-gray-400">
                Something went wrong, please try again later.
              </p>
              <Link href="/">Go back home</Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
