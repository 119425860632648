import clsx from 'clsx';
import { ReactNode } from 'react';

type AvatarSize = 24 | 32 | 40 | 48 | 56 | 64;

type Props = {
  size?: AvatarSize;
  children: ReactNode;
};

const sizeClasses: Record<AvatarSize, string> = {
  24: 'w-6 h-6 text-xs',
  32: 'w-8 h-8 text-sm',
  40: 'w-10 h-10 text-base',
  48: 'w-12 h-12 text-lg',
  56: 'w-14 h-14 text-xl',
  64: 'w-16 h-16 text-2xl',
};

export const Avatar = ({ size = 32, children }: Props) => {
  const classNames = clsx(
    'bg-product-light text-product-dark flex items-center justify-center rounded-full font-medium',
    sizeClasses[size],
  );

  return <div className={classNames}>{children}</div>;
};
