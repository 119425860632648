import { Cache, UpdateResolver } from '@urql/exchange-graphcache';
import { isRecord } from '@utils';
import { validate as isUuid } from 'uuid';

export type IFieldOrResolver = string | UpdateResolver;
export type IFieldsOrResolvers = IFieldOrResolver | IFieldOrResolver[];

export function invalidateCacheByFieldName({
  cache,
  fieldNameOrNames,
}: {
  cache: Cache;
  fieldNameOrNames: string | string[];
}) {
  const fieldSet = Array.isArray(fieldNameOrNames)
    ? new Set(fieldNameOrNames)
    : new Set([fieldNameOrNames]);

  cache
    .inspectFields('Query')
    .filter((field) => fieldSet.has(field.fieldName))
    .forEach((field) => {
      cache.invalidate('Query', field.fieldKey);
    });
}

/**
 * Config for URQL cache invalidation.
 *
 * Map of mutation names to query name(s) that should be invalidated for when the mutation is executed.
 *
 * @example
 * // Invalidate 'inventories' will query after the 'createInventory' mutation
 * createInventory: 'inventories'
 * // Invalidates ['inventories', 'products'] will query after the 'updateInventory' mutation
 * updateInventory: ['inventories', 'products']
 *
 * @type {Record<string, string | string[]>}
 * @property {string} key - The name of the mutation
 * @property {string | string[]} value - The name(s) of the query(s) that should be invalidated
 */
export const mutationToGetManyConfig: Record<string, IFieldsOrResolvers> = {
  createInventory: 'inventories',

  updateInventory: (_, _args, cache, info) => {
    if (
      isRecord(info.parent.updateInventory) &&
      typeof info.parent.updateInventory.id === 'string' &&
      isUuid(info.parent.updateInventory.id)
    ) {
      cache.invalidate({
        __typename: 'Inventory',
        id: info.parent.updateInventory.id,
      });
    }
  },

  deleteInventory: (_, _args, cache, info) => {
    if (
      isRecord(info.parent.deleteInventory) &&
      typeof info.parent.deleteInventory.deletedInventoryId === 'string' &&
      isUuid(info.parent.deleteInventory.deletedInventoryId)
    ) {
      cache.invalidate({
        __typename: 'Inventory',
        id: info.parent.deleteInventory.deletedInventoryId,
      });
    }
  },

  updateLineItemInOrder: (_, _args, cache, info) => {
    if (
      isRecord(info.parent.updateLineItemInOrder) &&
      typeof info.parent.updateLineItemInOrder.id === 'string' &&
      isUuid(info.parent.updateLineItemInOrder.id)
    ) {
      cache.invalidate({
        __typename: 'Order',
        id: info.parent.updateLineItemInOrder.id,
      });
    }
  },

  updateOrder: (_, _args, cache, info) => {
    if (
      isRecord(info.parent.updateOrder) &&
      typeof info.parent.updateOrder.id === 'string' &&
      isUuid(info.parent.updateOrder.id)
    ) {
      cache.invalidate({
        __typename: 'Order',
        id: info.parent.updateOrder.id,
      });
    }
  },

  updatePartnerFulfillment: (_, _args, cache, info) => {
    if (
      isRecord(info.parent.updatePartnerFulfillment) &&
      isRecord(info.parent.updatePartnerFulfillment.order) &&
      typeof info.parent.updatePartnerFulfillment.order.id === 'string' &&
      isUuid(info.parent.updatePartnerFulfillment.order.id)
    ) {
      cache.invalidate({
        __typename: 'Order',
        id: info.parent.updatePartnerFulfillment.order.id,
      });
    }
  },

  createOfferGroup: ['offerGroups', 'offerGroup', 'offers'],
  updateOfferGroupContent: ['offerGroups', 'offerGroup', 'offers'],
  updateAssetUploadState: ['assets'],
  deleteLatestOfferGroupVersion: ['offerGroups', 'offerGroup', 'offers'],

  updateOfferGroup: (_, _args, cache, info) => {
    if (info.variables['skipCacheInvalidation']) {
      return;
    }

    invalidateCacheByFieldName({
      cache,
      fieldNameOrNames: ['offerGroups', 'offerGroup', 'offers'],
    });
  },
};
