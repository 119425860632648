import { Tooltip, TooltipProps } from '@components/common/Tooltip/Tooltip';
import { cloneElementWithClass } from '@utils';
import { ReactNode } from 'react';

type Props = {
  onClick: () => void;
  icon: ReactNode;
  hoverTooltip?: TooltipProps;
  disabled?: boolean;
  disabledButtonTooltip?: TooltipProps;
};

export const IconButton = ({
  onClick,
  icon,
  hoverTooltip,
  disabled,
  disabledButtonTooltip,
}: Props) => {
  if (disabled && !disabledButtonTooltip) {
    throw new Error(
      'IconButton: disabledButtonTooltip is required when button is disabled',
    );
  }

  const Icon = icon ? cloneElementWithClass(icon, 'h-6 w-6') : null;

  const iconButton = (
    <button
      type="button"
      onClick={onClick}
      className="focus-visible:ring-product-dark relative rounded-full bg-transparent text-gray-900 hover:text-gray-400 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-hidden disabled:text-gray-500"
      disabled={disabled}
    >
      {Icon}
    </button>
  );

  if (disabled && disabledButtonTooltip) {
    return <Tooltip {...disabledButtonTooltip}>{iconButton}</Tooltip>;
  }

  if (hoverTooltip) {
    return <Tooltip {...hoverTooltip}>{iconButton}</Tooltip>;
  }

  return iconButton;
};
