import { STATSIG_CLIENT_PORTAL_SDK_KEY } from '@constants/public-api-keys';
import { useUser } from '@hooks/useUser';
import {
  StatsigProvider as StatsigDefaultProvider,
  StatsigUser,
} from '@statsig/react-bindings';
import { StatsigSessionReplayPlugin } from '@statsig/session-replay';
import { StatsigAutoCapturePlugin } from '@statsig/web-analytics';
import { isModeDev, isModeE2E, isModeStaging } from '@utils';
import { PropsWithChildren } from 'react';

export function StatsigProvider({ children }: PropsWithChildren) {
  const { user } = useUser();

  if (isModeE2E || isModeDev || isModeStaging) {
    return children;
  }

  const statsigUser: StatsigUser = {
    userID: user?.userProfile?.email,
    custom: {
      role: Array.from(user?.roleSet),
      environment: import.meta.env.MODE,
    },
    userAgent: navigator.userAgent,
  };

  return (
    <StatsigDefaultProvider
      sdkKey={STATSIG_CLIENT_PORTAL_SDK_KEY}
      user={statsigUser}
      options={{
        plugins: [
          new StatsigSessionReplayPlugin(),
          new StatsigAutoCapturePlugin(),
        ],
      }}
    >
      {children}
    </StatsigDefaultProvider>
  );
}
