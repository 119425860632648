const IS_SERVER = typeof window === 'undefined';

export function isServer() {
  return IS_SERVER;
}

export function isClient() {
  return !isServer();
}

export const isModeE2E = import.meta.env.MODE === 'e2e';
export const isModeDev = import.meta.env.VITE_DEPLOYMENT_ENV === 'dev';
export const isModeStaging = import.meta.env.VITE_DEPLOYMENT_ENV === 'stg';
export const isModeProd = import.meta.env.VITE_DEPLOYMENT_ENV === 'prod';
