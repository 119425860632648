import { LogoutButton } from '@components/auth/LogoutButton';
import { User } from '@contexts/User/user';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { userNavigation } from '@routes/nav-routes';
import { capitalize, sliceString } from '@utils';
import { Link } from 'react-router-dom';
import { Avatar } from '../Avatar/Avatar';

type NavMenuProps = {
  user: User;
};

export const UserMenu = ({ user }: NavMenuProps) => {
  return (
    <Menu as="div" className="relative">
      <MenuButton className="relative flex rounded-full bg-white text-sm">
        <Avatar>{capitalize(sliceString(user?.name ?? '', 1))}</Avatar>
      </MenuButton>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white py-1 shadow-lg transition focus:outline-hidden data-closed:scale-95 data-closed:transform data-closed:opacity-0 data-enter:duration-200 data-enter:ease-out data-leave:duration-75 data-leave:ease-in"
      >
        <MenuItem disabled>
          <div className="px-4 py-3 text-sm text-gray-900">
            <div>{user?.name}</div>
            <div className="truncate font-medium">
              {user?.userProfile?.email || 'emal'}
            </div>
          </div>
        </MenuItem>
        <MenuItem>
          <div className="divide-y divide-gray-200">
            {userNavigation
              .filter((item) => !item.icon)
              .map((item) => (
                <Link
                  key={item.name}
                  to={item.href ?? '#'}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {item.name}
                </Link>
              ))}
          </div>
        </MenuItem>
        <MenuItem>
          <div className="w-full pt-1">
            <LogoutButton className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100" />
          </div>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};
